@font-face {
  font-family: "Press Start 2P";
  src: url(/fonts/PressStart2P.woff); }

/* Brand color */
/* Darker blue */
::selection {
  background: #ED3D95;
  color: white; }

@keyframes fade {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slideVertical {
  0% {
    transform: translateY(5rem); }
  100% {
    transform: translateY(0); } }

@keyframes blink {
  0%, 100% {
    opacity: 0; }
  50% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; } }

@keyframes animate-background {
  from {
    background-position: top -5rem left -2rem, center right -2rem; } }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

body {
  color: #233659;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6;
  background-color: white;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: 55vw, auto 100vh;
  background-position: top left, top right;
  animation: animate-background 750ms cubic-bezier(0, 0, 0.2, 1);
  background-image: url('data:image/svg+xml;utf8,<svg fill="#FFF450" viewBox="0 0 994 558" xmlns="http://www.w3.org/2000/svg"><path d="M0 0v558L994 0z"/></svg>'), url('data:image/svg+xml;utf8,<svg fill="#00B9F2" viewBox="0 0 454 975" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h454v975C221.907 702.66 60.811 368.062 0 0z"/></svg>'); }
  @media screen and (max-width: 999px) {
    body {
      background-size: 60vw, 60vw; } }

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 15% 5%; }
  @media screen and (min-width: 1200px) {
    .container {
      padding: 5%; } }

.btn {
  font-family: "Press Start 2P", monospace;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none;
  background-color: #00B9F2;
  color: white;
  width: max-content;
  border: 4px solid transparent;
  border-image-source: url(/img/twilio-quest-pixel-border-blue.svg);
  border-image-slice: 8;
  border-image-outset: 4px;
  padding: 0.6rem 1rem 0.5rem;
  box-shadow: 0 4px 10px 0 rgba(35, 54, 89, 0.25); }

ol, ul {
  list-style-position: inside; }

@font-face {
  font-family: "Press Start 2P";
  src: url(/fonts/PressStart2P.woff); }

@keyframes bootUp {
  0% {
    opacity: 0;
    filter: blur(2px) hue-rotate(-100deg); } }

@keyframes glitch {
  0% {
    text-shadow: 0 0 30px, 20px 3px #ed145b, -20px -5px rgba(0, 255, 255, 0.2); }
  50% {
    text-shadow: 3px 5px rgba(237, 20, 91, 0.7), 5px -1px cyan; } }

@keyframes imgGlitch {
  0% {
    filter: drop-shadow(10px 3px rgba(255, 0, 255, 0.2)) drop-shadow(-20px -5px rgba(0, 255, 255, 0.2)); }
  50% {
    filter: hue-rotate(45deg) drop-shadow(-5px -5px rgba(255, 0, 255, 0.5)); } }

@keyframes flicker {
  0% {
    opacity: 1; }
  25% {
    opacity: 0.95; }
  100% {
    opacity: 0.93; } }

@keyframes screenBanding {
  0% {
    transform: translate3d(0, 200%, 0); } }

.twlo-quest-promo {
  font-family: "Press Start 2P";
  line-height: 1.8;
  text-align: center;
  -webkit-font-smoothing: none; }

.twlo-quest-promo__container {
  position: relative;
  padding: 1rem;
  color: white;
  background: #152748;
  overflow: hidden;
  animation: bootUp 1s 400ms backwards;
  backface-visibility: hidden; }
  @media screen and (min-width: 480px) {
    .twlo-quest-promo__container {
      padding: 2rem; } }

[class^="twlo-quest-promo__screen-overlay-"] {
  z-index: 20;
  position: absolute;
  pointer-events: none;
  transition: filter 5s;
  animation: screenBanding 10s alternate infinite; }

.twlo-quest-promo__container:hover [class^="twlo-quest-promo__screen-overlay-"] {
  filter: hue-rotate(205deg); }

.twlo-quest-promo__screen-overlay-1 {
  background-color: rgba(237, 20, 91, 0.03);
  top: 48%;
  right: 0;
  bottom: 0;
  left: 0; }

.twlo-quest-promo__screen-overlay-2 {
  background-color: rgba(237, 20, 91, 0.03);
  top: 38%;
  right: 0;
  bottom: 0;
  left: 0; }

.twlo-quest-promo__screen-overlay-3 {
  background-image: linear-gradient(to left, rgba(237, 20, 91, 0.02), rgba(54, 213, 118, 0.04));
  top: 48%;
  right: 0;
  bottom: 45%;
  left: 0; }

.twlo-quest-promo__screen-overlay-4 {
  background-image: linear-gradient(to right, transparent, rgba(255, 244, 80, 0.03));
  top: 35%;
  right: 0;
  bottom: 60%;
  left: 0; }

.twlo-quest-promo__container:before,
.twlo-quest-promo__container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none; }

.twlo-quest-promo__container:before {
  background-image: url(/img/twilio-quest-scanlines.svg);
  opacity: 0.15;
  z-index: 20; }

.twlo-quest-promo__container:after {
  opacity: 0.1;
  background-image: radial-gradient(at 35% 35%, rgba(232, 232, 232, 0.6), transparent);
  z-index: 10; }

.twlo-quest-promo__content {
  margin-bottom: 5%; }
  .twlo-quest-promo__content:hover .twlo-quest-promo__logo {
    animation: imgGlitch 100ms 4 alternate; }
  .twlo-quest-promo__content:hover .twlo-quest-promo__subtitle {
    animation: glitch 100ms 6 alternate; }

.twlo-quest-promo__logo {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  filter: blur(0.5px);
  animation: flicker 75ms infinite; }

.twlo-quest-promo__scoreboard {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  text-transform: uppercase;
  text-align: right;
  width: 100%; }

.twlo-quest-promo__player-one {
  text-shadow: 0 0 20px rgba(255, 244, 80, 0.5), 1px 2px rgba(237, 20, 91, 0.8), -1px -2px rgba(0, 255, 255, 0.4);
  color: #FFF450; }

.twlo-quest-promo__top-score {
  text-shadow: 0 0 20px #ff001e, 1px 3px 1px rgba(237, 20, 91, 0.2);
  color: #ff001e; }

.twlo-quest-promo__score {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.8), 1px 2px rgba(237, 20, 91, 0.4), -2px -1px rgba(0, 255, 255, 0.5);
  display: block;
  color: white; }

.twlo-quest-promo__subtitle {
  font: inherit;
  font-size: 1rem;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 1rem;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.5), 1px 2px rgba(237, 20, 91, 0.6), 0 -2px rgba(0, 255, 255, 0.5);
  margin-bottom: 3rem; }
  @media screen and (min-width: 480px) {
    .twlo-quest-promo__subtitle {
      font-size: 1.25rem;
      margin-top: 0; } }

a.twlo-quest-promo__btn {
  font: inherit;
  text-transform: uppercase;
  font-size: 0.75rem;
  text-decoration: none;
  color: white;
  background-color: #36D576;
  padding: 1.2rem 1rem;
  white-space: nowrap;
  width: 100%;
  border: 4px solid transparent;
  border-image-source: url(/img/twilio-quest-pixel-border-white.svg);
  border-image-slice: 4;
  border-image-outset: 4px;
  filter: drop-shadow(4px 4px 0 black); }
  @media screen and (min-width: 480px) {
    a.twlo-quest-promo__btn {
      font-size: 0.9rem; } }

.twlo-quest-promo__btn-img {
  height: 32px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-2px); }

.header {
  overflow: hidden;
  animation: fade 750ms;
  border-bottom: 1px solid; }
  @media screen and (min-width: 1200px) {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between; } }

.header-text {
  width: 100%; }
  @media screen and (min-width: 1200px) {
    .header-text {
      min-width: 600px;
      margin-right: 5%; } }

.signal-logo {
  display: inline-block;
  margin-bottom: 3%; }

.hackpack-logo {
  max-width: 100%;
  display: block;
  margin-bottom: 5%; }

.header-images {
  position: relative;
  z-index: 10;
  display: flex;
  height: auto;
  width: 100%;
  max-width: 450px;
  min-height: 500px;
  margin-top: 3rem;
  backface-visibility: hidden; }
  @media screen and (min-width: 500px) {
    .header-images {
      align-items: center;
      height: 800px;
      margin-top: 0; } }

.hackpack,
.hackpack-lights {
  position: absolute;
  width: 100%; }

.hackpack-lights {
  z-index: 10; }

a.link {
  color: #233659;
  font-weight: bold;
  position: relative; }

.terminal a.link:hover {
  background: #ED3D95;
  color: white; }

.terminal {
  font-family: 'lekton';
  font-size: 1.3rem;
  line-height: 1.5;
  backface-visibility: hidden; }

.event-info {
  margin-bottom: 1ex; }

.with-love {
  transform: translateY(3px); }

.divider {
  overflow: hidden;
  margin-top: 1ex;
  margin-bottom: 1ex; }

.header-cta {
  margin-top: 3rem; }

html.js-enabled .output {
  opacity: 0;
  transition: opacity 200ms; }

html.js-enabled .output.visible {
  opacity: 1; }

html.js-enabled .js-fallback {
  display: none; }

.cursor,
.typed-cursor {
  background: #ED3D95;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 25px; }
  .cursor.hidden,
  .typed-cursor.hidden {
    display: none; }

.cursor {
  animation: 1s blink step-end infinite; }

.section {
  display: flex;
  justify-content: space-between; }
  .section:not(:last-of-type) {
    border-bottom: 1px solid; }
  .section.-intro {
    border-bottom: none; }
  @media screen and (max-width: 899px) {
    .section {
      flex-direction: column; } }

.section-content a, .section-content a:visited {
  color: #ED3D95; }

.section-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center; }
  @media screen and (min-width: 480px) {
    .section-column {
      margin: 0 2rem; } }

.section-row {
  margin-bottom: 5rem; }

.section-image {
  max-width: 100%;
  align-self: center; }
  @media screen and (max-width: 899px) {
    .section-image {
      margin-bottom: 3rem; } }

.section-title {
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: 900; }
  .section-title.-intro {
    font-family: "Press Start 2P", monospace;
    color: #ED3D95;
    -webkit-font-smoothing: none;
    margin-bottom: 2rem; }
  .section-title.-center {
    text-align: center;
    margin-top: 5rem; }

.section-body {
  max-width: 55ch;
  margin-bottom: 2rem; }
  .section-row .section-body {
    min-height: 150px; }

.section-list {
  list-style-position: inside; }

@media screen and (min-width: 900px) {
  .always-be-knolling {
    max-width: 320px; }
  .coin {
    max-width: 306px; }
  .hero {
    max-width: 417px; }
  .led-display {
    max-width: 439px; }
  .section-icon {
    width: 75px;
    margin-bottom: 1rem; } }

.fullwidth {
  display: flex;
  justify-content: space-between; }
  .fullwidth:not(:last-of-type) {
    border-bottom: 1px solid; }
  .fullwidth.-intro {
    border-bottom: none; }
  @media screen and (max-width: 899px) {
    .fullwidth {
      flex-direction: column; } }

.fullwidth-content a, .fullwidth-content a:visited {
  color: #ED3D95; }

.fullwidth-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center; }
  @media screen and (min-width: 480px) {
    .fullwidth-column {
      margin: 0 2rem; } }

.fullwidth-row {
  margin-bottom: 5rem; }

.fullwidth-body {
  max-width: 110ch;
  margin-bottom: 2rem; }
  .fullwidth-row .fullwidth-body {
    min-height: 150px; }

.fullwidth-title {
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: 900; }
  .fullwidth-title.-intro {
    font-family: "Press Start 2P", monospace;
    color: #ED3D95;
    -webkit-font-smoothing: none;
    margin-bottom: 2rem; }
  .fullwidth-title.-center {
    text-align: center;
    margin-top: 5rem; }

footer {
  text-align: center;
  padding-bottom: 15%; }

.octocat {
  display: block;
  margin: 0 auto;
  margin-bottom: 3rem; }

.secondary-ctas {
  margin-top: 3rem; }
  .secondary-ctas .link {
    display: block;
    margin-bottom: 1rem; }
