* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: $midnight;
  font-family: $sans;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6;

  background-color: white;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: 55vw, auto 100vh;
  background-position: top left, top right;
  animation: animate-background 750ms $ease-out;
  background-image:
    url('data:image/svg+xml;utf8,<svg fill="#{$yellow}" viewBox="0 0 994 558" xmlns="http://www.w3.org/2000/svg"><path d="M0 0v558L994 0z"/></svg>'),
    url('data:image/svg+xml;utf8,<svg fill="#{$blue}" viewBox="0 0 454 975" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h454v975C221.907 702.66 60.811 368.062 0 0z"/></svg>');

  @include below(1000px) {
    background-size: 60vw, 60vw;
  }
}

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 15% 5%;
  @include above(1200px) {
    padding: 5%;
  }
}

@mixin pixel-border-blue {
  border: 4px solid transparent;
  border-image-source: url(/img/twilio-quest-pixel-border-blue.svg);
  border-image-slice: 8;
  border-image-outset: 4px;
}

.btn {
  font-family: $retro;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-decoration: none;
  background-color: $blue;
  color: white;
  width: max-content;
  @include pixel-border-blue;
  padding: 0.6rem 1rem 0.5rem;
  box-shadow: 0 4px 10px 0 rgba($midnight, 0.25);
}

ol, ul {
  list-style-position: inside;
}
