$red: #F22F46; /* Brand color */
$oxford: #152748; // Darkest blue
$midnight: #233659; /* Darker blue */
$dusk: #565B73; // Faded Blue
$smoke: #94979B; // Dark grey
$ash: #E8E8E8; // Middle grey
$chalk: #F5F5F5; // Light grey
$snow: #F9F9F9; // Lightest grey
$blue: #00B9F2;
$yellow: #FFF450;
$pink: #ED3D95;
$red: #ed145b;
$purple: #393092;
$chalk: #f5f5f5;
$green: #36D576;

::selection {
  background: $pink;
  color: white;
}
