.header {
  overflow: hidden;
  animation: fade 750ms;
  border-bottom: 1px solid;
  @include above(1200px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.header-text {
  width: 100%;
  @include above(1200px) {
    min-width: 600px;
    margin-right: 5%;
  }
}

.signal-logo {
  display: inline-block;
  margin-bottom: 3%;
}

.hackpack-logo {
  max-width: 100%;
  display: block;
  margin-bottom: 5%;
}

.header-images {
  position: relative;
  z-index: 10;
  display: flex;
  height: auto;
  width: 100%;
  max-width: 450px;
  min-height: 500px;
  margin-top: 3rem;
  backface-visibility: hidden;
  @include above(500px) {
    align-items: center;
    height: 800px;
    margin-top: 0;
  }
}

.hackpack,
.hackpack-lights {
  position: absolute;
  width: 100%;
}

.hackpack-lights {
  z-index: 10;
}

a.link {
  color: $midnight;
  font-weight: bold;
  position: relative;
}

.terminal a.link {
  &:hover {
    background: $pink;
    color: white;
  }
}

.terminal {
  font-family: 'lekton';
  font-size: 1.3rem;
  line-height: 1.5;
  backface-visibility: hidden;
}

.event-info {
  margin-bottom: 1ex;
}

.with-love {
  transform: translateY(3px);
}

.divider {
  overflow: hidden;
  margin-top: 1ex;
  margin-bottom: 1ex;
}

.header-cta {
  margin-top: 3rem;
}


html.js-enabled .output { opacity: 0; transition: opacity 200ms; }
html.js-enabled .output.visible { opacity: 1; }
html.js-enabled .js-fallback { display: none; }

.cursor,
.typed-cursor {
  background: $pink;
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 25px;
  &.hidden { display: none; }
}

.cursor {
  animation: 1s blink step-end infinite;
}
