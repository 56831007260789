footer {
  text-align: center;
  padding-bottom: 15%;
}

.octocat {
  display: block;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.secondary-ctas {
  margin-top: 3rem;
  .link {
    display: block;
    margin-bottom: 1rem;
  }
}
