$small:  480px;
$medium: 900px;
$large:  1200px;
$xlarge: 1400px;

@mixin above($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin below($width) {
  @media screen and (max-width: $width - 1) {
    @content;
  }
}
