@font-face {
  font-family: "Press Start 2P";
  src: url(/fonts/PressStart2P.woff);
}

@keyframes bootUp {
  0% { opacity: 0; filter: blur(2px) hue-rotate(-100deg); }
}

@keyframes glitch {
  0% { text-shadow: 0 0 30px, 20px 3px $red, -20px -5px rgba(cyan, 0.2); }
  50% { text-shadow: 3px 5px rgba($red, 0.7), 5px -1px cyan; }
}

@keyframes imgGlitch {
  0% { filter: drop-shadow(10px 3px rgba(magenta, 0.2)) drop-shadow(-20px -5px rgba(cyan, 0.2)); }
  50% { filter: hue-rotate(45deg) drop-shadow(-5px -5px rgba(magenta, 0.5)); }
}

@keyframes flicker {
  0% { opacity: 1; }
  25% { opacity: 0.95; }
  100% { opacity: 0.93; }
}

@keyframes screenBanding {
  0% { transform: translate3d(0, 200%, 0); }
}

.twlo-quest-promo {
  font-family: "Press Start 2P";
  line-height: 1.8;
  text-align: center;
  -webkit-font-smoothing: none;
}

.twlo-quest-promo__container {
  position: relative;
  padding: 1rem;
  color: white;
  background: $oxford;
  overflow: hidden;
  animation: bootUp 1s 400ms backwards;
  backface-visibility: hidden;
  @include above($small) {
    padding: 2rem;
  }
}

[class^="twlo-quest-promo__screen-overlay-"] {
  z-index: 20;
  position: absolute;
  pointer-events: none;
  transition: filter 5s;
  animation: screenBanding 10s alternate infinite;
}

.twlo-quest-promo__container:hover {
  [class^="twlo-quest-promo__screen-overlay-"] {
    filter: hue-rotate(205deg);
  }
}

.twlo-quest-promo__screen-overlay-1 {
  background-color: rgba($red, 0.03);
  top: 48%;
  right: 0;
  bottom: 0;
  left: 0;
}

.twlo-quest-promo__screen-overlay-2 {
  background-color: rgba($red, 0.03);
  top: 38%;
  right: 0;
  bottom: 0;
  left: 0;
}

.twlo-quest-promo__screen-overlay-3 {
  background-image: linear-gradient(to left, rgba($red, 0.02), rgba($green, 0.04));
  top: 48%;
  right: 0;
  bottom: 45%;
  left: 0;
}

.twlo-quest-promo__screen-overlay-4 {
  background-image: linear-gradient(to right, transparent, rgba($yellow, 0.03));
  top: 35%;
  right: 0;
  bottom: 60%;
  left: 0;
}

.twlo-quest-promo__container:before,
.twlo-quest-promo__container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
}

.twlo-quest-promo__container:before {
  background-image: url(/img/twilio-quest-scanlines.svg);
  opacity: 0.15;
  z-index: 20;
}

.twlo-quest-promo__container:after {
  opacity: 0.1;
  background-image: radial-gradient(at 35% 35%, rgba($ash, 0.6), transparent);
  z-index: 10;
}

.twlo-quest-promo__content {
  margin-bottom: 5%;
  &:hover {
    .twlo-quest-promo__logo { animation: imgGlitch 100ms 4 alternate; }
    .twlo-quest-promo__subtitle { animation: glitch 100ms 6 alternate; }
  }
}

.twlo-quest-promo__logo {
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  filter: blur(0.5px);
  animation: flicker 75ms infinite;
}

.twlo-quest-promo__scoreboard {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  text-transform: uppercase;
  text-align: right;
  width: 100%;
}

.twlo-quest-promo__player-one {
  text-shadow: 0 0 20px rgba($yellow, 0.5), 1px 2px rgba($red, 0.8), -1px -2px rgba(cyan, 0.4);
  color: $yellow;
}

.twlo-quest-promo__top-score {
  text-shadow: 0 0 20px #ff001e, 1px 3px 1px rgba($red, 0.2);
  color: #ff001e;
}

.twlo-quest-promo__score {
  text-shadow: 0 0 20px rgba(white, 0.8), 1px 2px rgba($red, 0.4), -2px -1px rgba(cyan, 0.5);
  display: block;
  color: white;
}

.twlo-quest-promo__subtitle {
  font: inherit;
  font-size: 1rem;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 1rem;
  text-shadow: 0 0 20px rgba(white, 0.5), 1px 2px rgba($red, 0.6), 0 -2px rgba(cyan, 0.5);
  margin-bottom: 3rem;
  @include above($small) {
    font-size: 1.25rem;
    margin-top: 0;
  }
}

@mixin drop-shadow {
  filter: drop-shadow(4px 4px 0 black);
}

@mixin pixel-border {
  border: 4px solid transparent;
  border-image-source: url(/img/twilio-quest-pixel-border-white.svg);
  border-image-slice: 4;
  border-image-outset: 4px;
}

a.twlo-quest-promo__btn {
  font: inherit;
  text-transform: uppercase;
  font-size: 0.75rem;
  text-decoration: none;
  color: white;
  background-color: $green;
  padding: 1.2rem 1rem;
  white-space: nowrap;
  width: 100%;
  @include pixel-border;
  @include drop-shadow;
  @include above($small) {
    font-size: 0.9rem;
  }
}

.twlo-quest-promo__btn-img {
  height: 32px;
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-2px);
}
