.fullwidth {
  display: flex;
  justify-content: space-between;
  &:not(:last-of-type) {
    border-bottom: 1px solid;
  }
  &.-intro {
    border-bottom: none;
  }
  @include below($medium) {
    flex-direction: column
  }
}

.fullwidth-content {
  a, a:visited {
    color: $pink;
  }
}

.fullwidth-column {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  @include above($small) {
    margin: 0 2rem;
  }
}

.fullwidth-row {
  margin-bottom: 5rem;
}

.fullwidth-body {
  max-width: 110ch;
  margin-bottom: 2rem;

  .fullwidth-row & {
    min-height: 150px;
  }
}

.fullwidth-title {
  font-size: 24px;
  margin-bottom: 1rem;
  font-weight: 900;
  &.-intro {
    font-family: $retro;
    color: $pink;
    -webkit-font-smoothing: none;
    margin-bottom: 2rem;
  }
  &.-center {
    text-align: center;
    margin-top: 5rem;
  }
}
