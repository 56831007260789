// ============================
//  Timing Functions
// ============================

// Quickly accelerate and slowly decelerate between locations.
$ease: cubic-bezier(0.4, 0.0, 0.2, 1);
// Start at full velocity and slowly decelerate to a resting point.
$ease-out: cubic-bezier(0.0, 0.0, 0.2, 1);
// Quickly accelerate at the beginning and level out at end.
$ease-in: cubic-bezier(0.4, 0.0, 1, 1);
// Pulls back then accelerates quickly beyond final resting point.
$bounce: cubic-bezier(0.5, -0.5, 0.5, 1.5);

@keyframes fade {
  0%, { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes slideVertical {
  0%, { transform: translateY(5rem); }
  100% { transform: translateY(0); }
}

@keyframes blink {
  0%, 100% { opacity: 0 }
  50% { opacity: 1 }
}

@keyframes fadeIn {
  0%, { opacity: 0 }
}

@keyframes animate-background {
  from { background-position: top -5rem left -2rem, center right -2rem; }
}
